import axios from "axios";
import Store, { AppKey } from "../../Global/Store";
import { Util } from "../../Global/Util";
import { Company } from "../../Models/Company";
import { ErrorResponse } from "../../Models/ErrorResponse";
import { Tenant } from "../../Models/Tenant";
import { baseURL, HttpRequest } from "../HttpRequest";
export class OrganisationalService {
  httpRequest: HttpRequest | null = null;

  constructor() {
    this.httpRequest = new HttpRequest(
      axios.create({
        baseURL: baseURL + "/gl",
        timeout: 600000,
      }),
      []
    );
  }

  async getUserTenant() {
    const store = new Store();
    var user = store.get(AppKey.USER);
    Util.log("User ====>", user);
    Util.log("Active User ====>", user?.activeUser);
    Util.log("Tenant ====>", user?.activeUser?.tenant);
    return user?.activeUser?.tenant;
  }

  async getTenants(search: string = "", size: number = 20) {
    const response = await this.httpRequest?.get<any | ErrorResponse>(
      search
        ? `/SearchTenants/${search}/${false}/${size}`
        : `/GetTenants/${size}`
    );
    return response;
  }

  async getCompanies(searchStr: string = "", size: number = 20) {
    const response = await this.httpRequest?.get<any | ErrorResponse>(
      searchStr
        ? `/SearchCompanies/${searchStr}/${size}`
        : `/GetCompanies/${size}`
    );
    return response;
  }

  async getFullCompany() {
    const response = await this.httpRequest?.get<any | ErrorResponse>(
      `/GetFullCompany`
    );
    return response;
  }

  async saveTenants(data: Tenant[]) {
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/SaveTenants`,
      data
    );
    return response;
  }

  async saveCompanies(data: Company[]) {
    const response = await this.httpRequest?.post<any | ErrorResponse>(
      `/SaveCompanies`,
      data
    );
    return response;
  }

  async getCurrencies(size: number = 20) {
    const response = await this.httpRequest?.get<any | ErrorResponse>(
      `/GetCurrencies/${size}`
    );
    return response;
  }

  async getLocales() {
    const response = await this.httpRequest?.get<any | ErrorResponse>(
      `/getlocales`
    );
    return response;
  }
}

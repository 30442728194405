import { AxiosInstance } from "axios";
import Store, { AppKey } from "../Global/Store";
import { Util } from "../Global/Util";
import { ErrorResponse } from "../Models/ErrorResponse";
import { HeaderKeyValue } from "./HeaderKeyValue";
export const baseURL = process.env.REACT_APP_API_BASEURL
export const cronURL = process.env.REACT_APP_CRON_BASEURL
export const requestTimeout = 600000;
//"https://api.applauseerpdemo.com";
// export const baseURL="https://staging-api.applauseerp.com";
export class HttpRequest {
  headerParams: any = {};
  reqObject: AxiosInstance;
  constructor(reqObject: AxiosInstance, headers: HeaderKeyValue[] = []) {
    const Environment = process.env.REACT_APP_ENV;
    Util.log("Environment:" + Environment)
    this.reqObject = reqObject;
    const store = new Store();
    if (store.get(AppKey.USER)) {
      headers.unshift(
        new HeaderKeyValue(
          "Authorization",
          `Bearer ${store.get(AppKey.USER).token}`
        )
      );
      headers.unshift(
        new HeaderKeyValue(
          "Token",
          `${store.get(AppKey.USER).activeUser.tenant}`
        )
      );
    }
    this.setHeader(headers);
    //register interceptor
    this.registerInterceptor();
  }
  private registerInterceptor() {
    this.reqObject.interceptors.request.use(
      (req) => {
        return req;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.reqObject.interceptors.response.use(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        var Er: ErrorResponse | undefined
        if (err.response) {
          Er = new ErrorResponse(
            err.response.data && err.response.data.Message ? err.response.data.Message : err.response.data ? err.response.data.message :
              `Status:${err.response.status} ${err.response.statusText}`,
            err.response.status,
            err.response.data?.Error,
            // err.response.data.StackTrace
          );
        }
        else {
          Er = new ErrorResponse(
            err.message
          );
        }
        return Promise.reject(Er ? Er : err);
      }
    );
  }
  async get<T>(url: string, headers: HeaderKeyValue[] = []) {
    this.setHeader(headers);
    try {
      return (await this.reqObject.get<T>(url, { headers: this.headerParams }))
        .data;
    } catch (err: any) {
      return err;
    }
  }
  async post<T>(url: string, data?: any, headers: HeaderKeyValue[] = []) {
    this.setHeader(headers);
    try {
      if (data) {
        var json = JSON.stringify(data);
      }
      return (
        await this.reqObject.post<T>(url, data, { headers: this.headerParams })
      ).data;
    } catch (err: any) {
      return err;
    }
  }
  async delete<T>(url: string, data?: any, headers: HeaderKeyValue[] = []) {
    this.setHeader(headers, url);
    try {
      return this.reqObject.delete<T>(url, {
        headers: this.headerParams,
        data: data,
      });
    } catch (err: any) {
      return err;
    }
  }
  async put<T>(url: string, data: any, headers: HeaderKeyValue[] = []) {
    this.setHeader(headers, url);
    return (
      await this.reqObject.put<T>(url, data, { headers: this.headerParams })
    ).data;
  }
  private setHeader(headers: HeaderKeyValue[], url: string = "") {
    // this.instance.defaults.baseURL = this.baseURL + url;
    headers.forEach((header) => {
      this.headerParams[header.key] = header.value;
      // this.instance.defaults.headers.common[header.key] = header.value;
    });
  }
}
